// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["l2uyqwdSb", "rxX11fSxM"];

const variantClassNames = {l2uyqwdSb: "framer-v-jsyzb1", rxX11fSxM: "framer-v-1x4gxk4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Gray: "l2uyqwdSb", Green: "rxX11fSxM"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "l2uyqwdSb"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "l2uyqwdSb", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-s9sDO", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-jsyzb1", className)} data-framer-name={"Gray"} layoutDependency={layoutDependency} layoutId={"l2uyqwdSb"} ref={ref} style={{backgroundColor: "rgba(255, 255, 255, 0.06)", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} {...addPropertyOverrides({rxX11fSxM: {"data-framer-name": "Green"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1my248v"} data-framer-name={"Progress"} layoutDependency={layoutDependency} layoutId={"wRHqTFVEN"} style={{backgroundColor: "rgb(148, 151, 158)"}} variants={{rxX11fSxM: {backgroundColor: "rgb(162, 228, 53)"}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-s9sDO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-s9sDO .framer-ejjofs { display: block; }", ".framer-s9sDO .framer-jsyzb1 { height: 4px; overflow: hidden; position: relative; width: 190px; will-change: var(--framer-will-change-override, transform); }", ".framer-s9sDO .framer-1my248v { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 60%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4
 * @framerIntrinsicWidth 190
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"rxX11fSxM":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const Frameref_ak0l1G: React.ComponentType<Props> = withCSS(Component, css, "framer-s9sDO") as typeof Component;
export default Frameref_ak0l1G;

Frameref_ak0l1G.displayName = "Progress bar (mockup)";

Frameref_ak0l1G.defaultProps = {height: 4, width: 190};

addPropertyControls(Frameref_ak0l1G, {variant: {options: ["l2uyqwdSb", "rxX11fSxM"], optionTitles: ["Gray", "Green"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Frameref_ak0l1G, [])